export default [
  {
    text: "Выбор номинации",
    link: { name: "StudentYearCollectiveNomination" },
    key: "nominations",
    disabled: false,
  },
  {
    text: "Данные",
    link: { name: "StudentYearCollectiveData" },
    key: "data",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "StudentYearCollectivePerformance" },
    key: "performance",
    disabled: false,
  },
  {
    text: "Портфолио",
    link: { name: "StudentYearCollectivePortfolio" },
    key: "portfolio",
    disabled: false,
  },
];
